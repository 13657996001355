import { React, useState, useEffect } from 'react'
import { Profile, Email, Avatar, Edit, Profile2, Email2, Email3 } from '../assets'
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const EditProfile = () => {
    const [selectedOption, setSelectedOption] = useState('profile');
    const [passwordVisible, setPasswordVisible] = useState(true);
    const placeholder = '\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022';
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleUpdateClick = () => {
        // Update the state with the new values
        console.log('Email:', email);
        console.log('Password:', password);
    };
    useEffect(() => {
        if (!window.localStorage.getItem('vachak_auth')) {
          window.location.href = '/signin';
        }
      }, []);

    return (
        <div style={{ fontFamily: "'Lato', sans-serif", marginTop: '1.5em', marginLeft: '3em' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2em' }}>
                <div
                    onClick={() => handleOptionClick('profile')}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '3em', borderBottom: selectedOption === 'profile' ? '2px solid #FE6D60' : 'none', paddingBottom: '.6em', cursor: 'pointer' }}>
                        {selectedOption === 'profile' ? (
                            <img src={Profile} />
                        ) : (
                            <img src={Profile2} />
                        )}
                        <div style={{ color: selectedOption === 'profile' ? '#426AFB' : '#4F4F50', paddingLeft: '.7em', fontSize: '20px' }}>Profile</div>
                    </div>
                </div>
                <div
                    onClick={() => handleOptionClick('verify')}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: selectedOption === 'verify' ? '2px solid #FE6D60' : 'none', paddingBottom: '.6em', cursor: 'pointer' }}>
                        {selectedOption === 'verify' ? (
                            <img src={Email2} />
                        ) : (
                            <img src={Email} />
                        )}
                        <div style={{ color: selectedOption === 'verify' ? '#426AFB' : '#4F4F50', paddingLeft: '.7em', fontSize: '20px' }}>Verify Email</div>
                    </div>
                </div>
            </div>

            <div>
                {selectedOption === 'profile' && (
                    <div style={{ width: '540px', height: '545px', backgroundColor: '#F3F6FC', borderRadius: '5px', border: '1px solid #CBD2E7', marginLeft: '453px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginLeft: '40%', marginTop: '2em' }}>
                            <img src={Avatar} />
                            <img src={Edit} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.5em', marginTop: '2em' }}>
                            <label style={{ fontWeight: '700', color: '#4F4F50' }}>Name</label>
                            <input type="name" placeholder='Anumekha Singh' style={{
                                width: '90%',
                                backgroundColor: '#D2DADE',
                                borderRadius: '5px',
                                border: '1.5px solid #c2c2c2',
                                height: '40px',
                                marginTop: '5px',
                                marginBottom: '1em',
                                color: '#7F8693',
                                paddingLeft: '.5em',
                                fontSize: '16px'
                            }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.5em', marginTop: '.3em' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '2.5em', cursor: 'pointer' }}>
                                <label style={{ fontWeight: '700', color: '#4F4F50' }}>Email</label>
                                <div onClick={handleUpdateClick} style={{ color: '#426AFB', fontWeight: '700' }} >Change Email</div>
                            </div>
                            <input type="email" value={email} onChange={handleEmailChange} placeholder='anumekha@xyz.com' style={{
                                width: '90%',
                                borderRadius: '5px',
                                border: '1px solid #667080',
                                height: '40px',
                                marginTop: '5px',
                                marginBottom: '1em',
                                color: '#7F8693',
                                paddingLeft: '.5em',
                                fontSize: '16px'
                            }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.5em', marginTop: '.3em' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '2.5em', cursor: 'pointer' }}>
                                <label style={{ fontWeight: '700', color: '#4F4F50' }}>Password</label>
                                <div onClick={handleUpdateClick} style={{ color: '#426AFB', fontWeight: '700' }}>Change Password</div>
                            </div>
                            <TextField
                                type={passwordVisible ?   'password' : 'text'}
                                value={password} onChange={handlePasswordChange} placeholder={placeholder}
                                InputProps={{
                                    style: {
                                        width: '92%',
                                        borderRadius: '5px',
                                        border: '1.5px solid #667080',
                                        height: '43px',
                                        marginTop: '5px',
                                        marginBottom: '1em',
                                        color: '#4F4F50',
                                        fontSize: '16px',
                                        backgroundColor:'white'
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                                {passwordVisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.5em', marginTop: '.5em' }}>
                            <label style={{ fontWeight: '700', color: '#4F4F50' }}>API Key</label>
                            <input type="name" placeholder='ABCDEF123QWERT' style={{
                                width: '90%',
                                borderRadius: '5px',
                                border: '1.5px solid #667080',
                                height: '40px',
                                marginTop: '5px',
                                marginBottom: '1em',
                                color: '#7F8693',
                                paddingLeft: '.5em',
                                fontSize: '16px'
                            }} />
                        </div>
                    </div>
                )}

                {selectedOption === 'verify' && (
                    <div>
                        <div style={{ width: '540px', height: '545px', backgroundColor: '#F3F6FC', borderRadius: '5px', border: '1px solid #CBD2E7', marginLeft: '453px', display:'flex', flexDirection:'column'}}>
                            <div style={{marginLeft:'22%', marginTop:'3em'}}>
                            <img src={Email3} style={{width:'300px', height:'170px'}}/>
</div>
<div style={{color:'#4F4F50', textAlign:'center', fontSize:'22px', fontWeight:'700', marginTop:'1.5em'}}>Verify your Email Address</div>
<div style={{color:'#7F8693', fontSize:'16px', marginTop:'1.5em', marginLeft:'3.2em', marginRight:'3.2em'}}>You've entered elizabeth@bennetcom as the email address for your account. Please verify this email address by clicking button below.</div>
                        <div style={{marginTop:'2.7em', marginLeft:'36%'}}><button style={{color:'white', backgroundColor:'#FE6D60', border:'1px solid #FE6D60', borderRadius:'5px', width:'150px', height:'40px', fontSize:'16px', cursor:'pointer' }}>Verify your Email</button></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditProfile