import {
  Fragment,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddUrl from '../TtsDataCard/Addurl';
import DataCard from '../TtsDataCard/DataCard';
import ElevateAppBar from '../TtsDataCard/ElevateAppBar';
import React from 'react';
import Ttsheader from '../TtsDataCard/Ttsheader'
import { Typography } from '@material-ui/core';
import { getClientData } from '../../redux/actions/clientActions';

const ApiCard = (props) => {
  const userLoginInfo = useSelector(state => state.loginData)
  useEffect(() => {
    if (!window.localStorage.getItem('vachak_auth')) {
      window.location.href = '/signin';
    }
  }, []);
  const dispatch = useDispatch();
  console.log(userLoginInfo.user._id)
  useEffect(() => {
    getList()
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }, [])
  const id = userLoginInfo.user._id;

  fetch('https://webreader.reverieinc.com/tts_web_api/basicinfo?id=' + id, {
    method: 'GET'
  })
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.error(error);
    });


  const getList = async () => {
    dispatch(getClientData(userLoginInfo.user)).then(() => {
      console.log('I am Inside HomePage')
    })
  }
  return (
    <Fragment >
      <Typography variant="h6" component="h2" align="center">
        {/* Welcome {userLoginInfo.user.name} */}
      </Typography>
      <Ttsheader user={userLoginInfo.user} />
      <AddUrl />
      <DataCard user={userLoginInfo.user} />
    </Fragment>

  );
}

export default ApiCard;
