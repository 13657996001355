import React, { Fragment } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { getClientData } from '../../redux/actions/clientActions';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({

  title: {
    flexGrow: 1,
  },
}));



export default function ElevateAppBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = props


  const logout = () => {
    window.location.reload();
    localStorage.removeItem("user");
  }

  return (
    <div className={classes.root}>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>TTS WEB READER CLIENT DASHBOARD</Typography>
          {user && user.apiKey &&
            <Fragment>
              <Button color="inherit" float="right" onClick={logout}>Logout</Button>
            </Fragment>
          }

        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}