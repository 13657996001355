import axios from '../../utils/customAxios';
import AuthAxios from "../../utils/authAxios";

export const login = (params) => {
  return axios.post('login', params);
}

export const authLogin = (params) => {
  return AuthAxios.post('auth/login', params);
}

export const authSignup = (params) => {
  return AuthAxios.post('auth/register', params);
}

export const checkAuth = async () =>{
  const authToken = window.localStorage.getItem('vachak_auth');
  console.log({authToken})
  if(authToken){
    return await AuthAxios.get('auth/check');
  }
  return null;
}