import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getClientData } from '../../redux/actions/clientActions';
import { makeStyles } from '@material-ui/core/styles';
import { Icon1, Icon2, Icon3, Icon4, Vector, arrowLeft } from '../../assets';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F3F6FC',
    alignItems: 'center',
    padding: theme.spacing(6),
    margin: theme.spacing(1),
    borderRadius: '10px',
    border: '1px solid #CBD2E7',
    height: '300px',
    color: '#4F4F50',
    lineHeight: '1.5',
  },
  icon: {
    width: '25%',
  },
}));




const TtsHeader = (props) => {
  const { user } = props;
  const dispatch = useDispatch();

  const clientData = useSelector(state => state.clientData)
  const classes = useStyles()

  const reloadPage = () => {
    dispatch(getClientData(user)).then(() => {
      console.log('I am Inside Header')
    })
  }

  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontFamily: "'Lato', sans-serif" }}>
        <div style={{fontSize:'1.3em'}}><img src={arrowLeft} alt="Arrow Left Icon" style={{ height: '1.3em', width: '1.3em' }} />
        </div>
        
        <div style={{fontSize: '1em', paddingRight: '.5em'}}><div style={{ color: '#667080', marginRight: '10px', paddingBottom: '.5em', paddingTop: '.5em' }}>Filter By</div>
  <button style={{ border: '1px solid #667080', width: '12em',height: '3.2em',backgroundColor: 'white', color: '#667080', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 15px', borderRadius: '5px' }}>
    Today <img src={Vector} alt="Arrow Icon" style={{ marginLeft: '5px', height: '10px', width: '15px' }} />
  </button></div>
  
</div>
<div className={classes.root} style={{ fontFamily: "'Lato', sans-serif" }}>
  <Grid container spacing={0} style={{ display: 'flex', flexWrap: 'nowrap' }}>
    <Grid item xs={3} className={classes.gridItem} >
      <img src={Icon1} alt="Icon 1" className={classes.icon} />
      <div style={{ marginTop: '10px', fontWeight: '700', fontSize: '16.44px'}}> No. of Plays</div>
      <div style={{fontSize: '28.93px'}}><strong>445000</strong></div>
    </Grid>
    <Grid item xs={3} className={classes.gridItem}>
      <img src={Icon2} alt="Icon 2" className={classes.icon} />
      <div style={{ marginTop: '10px', fontSize: '16.44px', fontWeight: '700' }}>Average Time</div>
      <div style={{fontSize: '28.93px'}}><strong>23:45:00</strong></div>
    </Grid>
    <Grid item xs={3} className={classes.gridItem}>
      <img src={Icon3} alt="Icon 3" className={classes.icon} />
      <div style={{ marginTop: '10px', fontSize: '16.44px', fontWeight: '700' }}>Average Speed</div>
      <div style={{fontSize: '28.93px'}}><strong>1.0x</strong></div>
    </Grid>
    <Grid item xs={3} className={classes.gridItem}>
      <img src={Icon4} alt="Icon 4" className={classes.icon} />
      <div style={{ marginTop: '10px', fontSize: '16.44px', fontWeight: '700' }}>Total Time</div>
      <div style={{fontSize: '28.93px'}}><strong>23:45:00</strong></div>
    </Grid>
  </Grid>
</div>

    </Fragment>
  )
}

export default TtsHeader;