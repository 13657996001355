import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';

import ElevateAppBar from "./components/TtsDataCard/ElevateAppBar.js";
import HomePage from './components/home/HomePage.js'
import Login from "./components/login/login"
import axios from 'axios';
import { clientLogin } from "./redux/actions/authAction.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IntegrationPage } from './components/integration/IntegrationPage.js'
import SignIn from './components/SignIn/SignIn.js'
import SignUp from './components/SignUp/SignUp.js'
import { checkUserAuth } from "./redux/actions/authAction.js";
import Layout from "./components/Layout/layout.component.js";
import BasicInfo from './components/BasicInfo.js';
import EditProfile from './components/EditProfile.js'

const App = () => {
    const [user, setLoginUser] = useState({})
    const dispatch = useDispatch();
    const userLoginInfo = useSelector(state => state.loginData);
    const isAuthenticated = window.localStorage.getItem('vachak_auth');
    useEffect(() => {
        dispatch(checkUserAuth())
    }, []);

    return (<div className="App">
        <Router>
            <Switch>
                <Route exact path="/">
                    {/* {userLoginInfo && userLoginInfo.user.apikey ? ( */}
                    {isAuthenticated  ? (
                        <Layout>
                            <HomePage user={user} setLoginUser={setLoginUser} />
                        </Layout>
                    ) : (
                        <SignIn setLoginUser={setLoginUser} />
                    )}

                </Route>
                <Route path="/signup">
                    {isAuthenticated ? (
                        <Redirect to="/" />
                    ) : (
                        <SignUp setLoginUser={setLoginUser} />
                    )}
                </Route>
                <Route path="/signin">
                    {isAuthenticated ? (
                        <Redirect to="/" />
                    ) : (
                        <SignIn setLoginUser={setLoginUser} />
                    )}
                </Route>
                <Route exact path="/integration">
                    {isAuthenticated ? (
                        <Layout>
                            <IntegrationPage />
                        </Layout>
                    ) : (
                        <SignIn setLoginUser={setLoginUser} />
                    )}
                </Route>
                <Route exact path="/basicinfo">
                    {isAuthenticated ? (
                        <BasicInfo />
                    ) : (
                        <SignIn setLoginUser={setLoginUser} />
                    )}
                </Route>
                <Route exact path="/edit-profile">
                    {isAuthenticated ? (
                        <Layout>
                            <EditProfile />
                        </Layout>
                    ) : (
                        <SignIn setLoginUser={setLoginUser} />
                    )}
                </Route>
            </Switch>
        </Router>
    </div>

    );
}

export default App;