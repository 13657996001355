import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { getClientData } from '../../redux/actions/clientActions';
import { makeStyles } from '@material-ui/core/styles';
import {Eye, Trash} from '../../assets'
import { Button } from '@material-ui/core';
import { styled, alpha } from '@mui/material/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import { Switch, FormControlLabel } from '@mui/material';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: lightGreen[500],
    '&:hover': {
      backgroundColor: alpha(lightGreen[500], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: lightGreen[500],
  },
}));


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: '.5em',
    marginRight: '.5em',
    border: '1px solid #CBD2E7',
    maxWidth: '100%',
    tableLayout: 'fixed',
    width: '100%',
    wordWrap: 'break-word',
  },
  tableHead: {
    backgroundColor: '#F3F6FC',
    border: '1px solid #CBD2E7',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableCell: {
    border: '1px solid #CBD2E7',
    padding: '10px',
    height: '40px'
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F3F4',
    },
  },
  actionCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
}));



const DataCard = (props) => {
  const clientData = useSelector(state => state.clientData)
  const dispatch = useDispatch();
  const { user } = props;
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  let [index, setIndex] = React.useState(0);

  const handleClickOpen = (id) => {
    setIndex(id)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeStatus = (audioObj) => {
    const audioId = audioObj.audioId;
    const status = audioObj.activeStatus;
    axios.post('https://webreader.reverieinc.com/tts_web_api/changeStatus', { audioId: audioId, status: !status })
      .then(() => {
        dispatch(getClientData(user)).then(() => {
          console.log('I am Inside Data Card')
        })
      })
  }

  function deleteObject(audioId) {
    axios.post('https://webreader.reverieinc.com/tts_web_api/deleteaudio', { audioId: audioId })
      .then(res => {
        alert(res.data.message)
        dispatch(getClientData(user)).then(() => {
          console.log('I am Inside Data Card')
        })
      })
      .catch(err => alert(err))
  }

  return (
    <Fragment>
      <div style={{fontFamily: "'Lato', sans-serif", fontSize: '1.2em', paddingBottom: '1.2em', paddingLeft: '.5em'}}>URL Management</div>
  <Table className={classes.table} size="small" aria-label="customized table" style={{ fontFamily: "'Lato', sans-serif" }}>
    <TableHead className={classes.tableHead}>
      <TableRow style={{height: '4em'}}>
        <TableCell className={classes.tableCell} align="center" style={{ width: '50%' }}><div style={{ fontFamily: "'Lato', sans-serif", fontSize:'18px', fontWeight:'700', color: '#4F4F50' }}>Web Link</div></TableCell>
        <TableCell className={classes.tableCell} align="center" style={{ width: '15%' }}><div style={{ fontFamily: "'Lato', sans-serif", fontSize:'18px', fontWeight:'700', color: '#4F4F50' }}>No. of Plays</div></TableCell>
        <TableCell className={classes.tableCell} align="center" style={{ width: '25%' }}><div style={{ fontFamily: "'Lato', sans-serif", fontSize:'18px', fontWeight:'700', color: '#4F4F50' }}>Actions</div></TableCell>
        <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}><div style={{ fontFamily: "'Lato', sans-serif", fontSize:'18px', fontWeight:'700', color: '#4F4F50' }}>Status</div></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {clientData.audioData && clientData.audioData.length > 0 && clientData.audioData.map((v, id) => {
        return (
          <TableRow key={id} className={classes.tableRow}>
            <TableCell className={classes.tableCell} style={{ color: '#7F8693' }}>{v.webLink}</TableCell>
            <TableCell className={classes.tableCell} align="center">{v.visitCount ? v.visitCount : 34500}</TableCell>
            <TableCell className={classes.tableCell} align="center">
              <Button style={{border: "2px solid #9BE284", backgroundColor: "white", marginRight: "10px", height: '2.5em'}}>
                 <img src={Eye} alt="View Data" style={{height: "20px", width: "20px", marginRight: "5px"}} /> 
                 <span style={{color: "#9BE284", textTransform: "none"}}>View Data</span> </Button>
                  <Button style={{border: "2px solid #FE6D60", backgroundColor: "white" , height: '2.5em'}}> 
                  <img src={Trash} alt="Flush Data" style={{height: "20px", width: "20px", marginRight: "5px"}} /> 
                  <span style={{color: "#FE6D60", textTransform: "none"}}>Flush Data</span> </Button> 
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {/* <Switch
                checked={v.activeStatus }
                onChange={() => { changeStatus(v) }}
                name="status"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                className={classes.toggleButton}
                color="white"
                defaultChecked-color="secondary"
              /> */}
              <FormControlLabel control={<GreenSwitch defaultChecked />} label="Label Text" />

            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  </Table>
</Fragment>
  )
}
export default DataCard;