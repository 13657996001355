import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { getClientData } from '../../redux/actions/clientActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      marginBottom: "30px"
    },
  },
}
));

const AddUrl = (props) => {
  const clientData = useSelector(state => state.clientData)
  const userLoginInfo = useSelector(state => state.loginData)
  const dispatch = useDispatch();

  // const { audioData, getList, user } = props;
  const [webLink, setwebLink] = useState()
  const classes = useStyles();

  const handleAddUrl = () => {
    document.getElementById('outlined-basic').value = ''
    let url = [];
    if(clientData && clientData.length > 0) {
       url = clientData.audioData.filter(ele => ele.webLink === webLink);
    }
    if (url.length > 0 && url[0].segments.length > 0 && url[0].femaleS3Url !== '') {
      alert('Audio is already present. Please search the Weblink')
    }
    else if (url.length > 0 && url[0].segments.length === 0 && url[0].femaleS3Url === '') {
      alert('Data is present.Please Visit the Url to generate the audio')
    }
    else {
      axios.post('https://webreader.reverieinc.com/tts_web_api/addurl', {
        webLink,
        apiKey: userLoginInfo.user.apiKey
      }).then((res) => {
        alert(res.data.message)
        dispatch(getClientData(clientData)).then(() => {
          console.log('I am Inside Add Url')
        })
      })
    }
  }
  const handleInput = (evt) => {
    const domain = evt.target.value;;
    setwebLink(domain)
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        id="outlined-basic"
        label="Add Url"
        name="Add Url"
        variant="outlined"
        style={{ width: '350px' }}
        onChange={handleInput}
      />
      <Icon className="fa fa-plus-circle" style={{ width: '30px', fontSize: 30, marginTop: 20, cursor: "pointer" }} color="primary" onClick={handleAddUrl} />
    </form>
    
  );
}

export default AddUrl;