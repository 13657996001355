import React, { useState, useEffect } from 'react';
import { Input, Button, Select } from 'antd';
import basicinfo1 from '../assets/basicinfo.svg';
import bottom from '../assets/bottom.svg';
import toastr from 'toastr';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const BasicInfo = () => {
    const [page, setPage] = useState(1);
    const [companyName, setCompanyName] = useState('');
    const [companyNameWarning, setCompanyNameWarning] = useState('');
    const [contact, setContact] = useState('');
    const [contactWarning, setContactWarning] = useState('');
    const [website, setWebsite] = useState('');
    const [websiteWarning, setWebsiteWarning] = useState('');
    const [industry, setIndustry] = useState('');
    const [industryWarning, setIndustryWarning] = useState('');
    const userLoginInfo = useSelector(state => state.loginData)
    const userId = userLoginInfo.user._id

    const history = useHistory();

    const validatePhoneNumber = () => {
        const phoneNumberRegex = /^\d+$/;
        if (contact && !phoneNumberRegex.test(contact)) {
            setContactWarning('Invalid phone number');
            setTimeout(() => {
                setContactWarning("");
            }, 1500);
            return false;
        }
        else {
            return true;
        }
    };

    const validateWebsite = () => {
        const websiteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
        if (website && !websiteRegex.test(website)) {
            setWebsiteWarning('Invalid website');
            setTimeout(() => {
                setWebsiteWarning("");
            }, 1500);
            return false;
        }
        else {
            return true;
        }
    };

    const warningPage1 = () => {
        if (!companyName || companyName.trim().length === 0) {
            setCompanyNameWarning("Please enter company name");
            setTimeout(() => {
                setCompanyNameWarning("");
            }, 1500);
            return
        }
        if (!website || website.trim().length === 0) {
            setWebsiteWarning("Please enter website name");
            setTimeout(() => {
                setWebsiteWarning("");
            }, 1500);
            return
        }
        if (!contact || contact.trim().length === 0) {
            setContactWarning("Please enter contact number");
            setTimeout(() => {
                setContactWarning("");
            }, 1500);
            return
        }
        return true
    };

    const warningPage2 = () => {
        if (!industry) {
            setIndustryWarning("Please select industry");
            setTimeout(() => {
                setIndustryWarning("");
            }, 1500);
            return
        }
        return true;
    }

    const handleSubmit = () => {

        const basicInfo = { companyName, contact, website, industry, userId }
        axios.post('https://webreader.reverieinc.com/tts_web_api/basicinfo', basicInfo)
            .then(response => {
                toastr.success("Data stored successfully");
            })
            .catch(error => {
                console.error('Error:', error);
                toastr.error("An error occurred while storing the data");
            });


    }

    const handlePageChange = (action) => {
        if (action === "back" && page === 1) return;
        if (action === "next" && page === 1) {
            if (warningPage1() && validateWebsite() && validatePhoneNumber()) {
                setPage(2);
            }
            return;
        }
        if (action === "back" && page === 2) {
            setPage(1);
            return;
        }
        if (action === "next" && page === 2) {
            if (warningPage2()) {
                handleSubmit();
                history.push('/')
            }
        }
    };



    return (
        <div style={{ display: 'grid', gridTemplateColumns: '52% 48%', height: '104vh', fontFamily: "'Lato', sans-serif", marginTop: '-2em' }}>
            <div style={{ backgroundColor: '#F0EFF4', width: '100%', height: '100%' }}>
                <div style={{ width: '60%', margin: '5em auto 3em' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '30px 1fr' }}>
                        <div>
                            <span style={{ height: '1px', width: '20px', borderBottom: '3px solid #EE4037', display: 'inline-block', }}></span>
                        </div>
                        <div >
                            <div style={{ fontSize: '28px', color: '#4F4F50', fontWeight: '700' }}>Let us know you <i>better</i> to serve you <i>better</i></div>
                            <div style={{ color: '#6A7488', fontWeight: '700', marginTop: '1em' }}>Knowing you better will help us to provide a better experience</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '60%', margin: '0 auto' }}>
                    {page === 1 &&
                        <div style={{ height: '40vh' }}>
                            <div style={{ marginTop: '2em' }}>
                                <label style={{ display: 'block', textAlign: 'left', color: '#7F8693', fontSize: '18px', marginBottom: '.2em' }}>
                                    Name / Business Name
                                </label>
                                <input
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    placeholder="Please enter your name/Business name"
                                    style={{
                                        width: '500px',
                                        borderRadius: '5px',
                                        border: '1px solid #DADCE0',
                                        height: '60px',
                                        paddingLeft: '1em',
                                        size: '14px',
                                        color: '#7F8693',
                                        fontFamily: "'Lato', sans-serif",
                                    }}
                                />
                                {companyNameWarning && (
                                    <div style={{ color: '#FE6D60', textAlign: 'left' }}>
                                        {companyNameWarning}
                                    </div>
                                )}
                            </div>
                            <div style={{ marginTop: '2em' }}>
                                <label style={{ display: 'block', textAlign: 'left', color: '#7F8693', fontSize: '18px', marginBottom: '.2em' }}>Website Name</label>
                                <input
                                    value={website}
                                    onChange={e => setWebsite(e.target.value)}
                                    placeholder="www.example.com"
                                    style={{
                                        width: '500px',
                                        borderRadius: '5px',
                                        border: '1px solid #DADCE0',
                                        height: '60px',
                                        paddingLeft: '1em',
                                        color: '#7F8693'
                                    }}
                                />
                                <style>
                                    {`input::placeholder { color: #C4C4C4; font-family: Lato;}`}
                                </style>
                                {websiteWarning && (
                                    <div style={{ color: '#FE6D60', textAlign: 'left' }}>
                                        {websiteWarning}
                                    </div>
                                )}
                            </div>

                            <div style={{ marginTop: '2em' }}>

                                <label style={{ display: 'block', textAlign: 'left', color: '#7F8693', fontSize: '18px', marginBottom: '.2em' }}>Contact Number</label>
                                <input value={contact} onChange={e => setContact(e.target.value)} onBlur={() => toastr.clear()} placeholder="90XXXXXXXX" style={{ width: '500px', borderRadius: '5px', border: '1px solid #DADCE0', height: '60px', paddingLeft: '1em', color: '#7F8693' }} />
                                {contactWarning && (
                                    <div style={{ color: '#FE6D60', textAlign: 'left' }}>
                                        {contactWarning}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {page === 2 &&
                        <div style={{ height: '40vh' }}>
                            <div style={{ marginTop: '2em', paddingTop: '2em' }}>
                                <label style={{ display: 'block', marginBottom: '.2em', color: '#7F8693', fontSize: '18px' }}>Select Industry</label>
                                <select
                                    value={industry}
                                    onChange={event => setIndustry(event.target.value)}
                                    id="Select Industry"
                                    className="industry-select"
                                    style={{
                                        width: '500px',
                                        borderRadius: '5px',
                                        border: '1px solid #DADCE0',
                                        height: '50px',
                                        backgroundColor: 'white',
                                        color: '#C4C4C4',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        paddingLeft: '1em',
                                        marginRight: '1em',
                                        appearance: 'none',
                                        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23F55D55' viewBox='0 0 1024 1024'%3E%3Cpath d='M512 768L256 512h512L512 768z'/%3E%3C/svg%3E")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'right center',
                                        paddingRight: '1em',
                                        backgroundSize: '50px 30px'

                                    }}
                                >
                                    <option value="" disabled selected>
                                        Select Industry
                                    </option>    <option value="bfsi">BFSI</option>
                                    <option value="ecommerce">ECommerce</option>
                                    <option value="government">Government</option>
                                </select>
                                {industryWarning && (
                                    <div style={{ color: '#FE6D60', textAlign: 'left' }}>
                                        {industryWarning}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div style={{ width: '60%', margin: '3em auto', display: 'flex', justifyContent: page === 1 ? "flex-end" : 'space-between' }}>
                    {page === 2 &&
                        <div>
                            <button onClick={e => handlePageChange('back')} style={{ border: '1px solid #F55D55', fontWeight: '700', padding: '1em', width: '123px', height: '49px', borderRadius: '5px', marginTop: '3em', marginRight: '-3em', backgroundColor: 'white', color: '#4F4F50', fontSize: '16px' }} className="basic-info-btn">Back</button>
                        </div>
                    }
                    <div>
                        <button onClick={(e) => handlePageChange('next')} style={{ border: '1px solid #F55D55', fontWeight: '700', padding: '1em', width: '123px', height: '49px', borderRadius: '5px', marginTop: '3em', marginRight: '-3em', backgroundColor: 'white', color: '#4F4F50', fontSize: '16px', pointer: 'cursor' }} className="basic-info-btn">{page === 1 ? "Next" : "Done"}</button>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={`carousel ${page === 1 ? "active" : ""}`}></div>
                    <div className={`carousel ${page === 2 ? "active" : ""}`}></div>
                    <style>
                        {`
            .carousel {
                width: 70px;
                height: 7px;
                background-color: #DADCE0;
                margin: 10px;
                border-radius: 5px;
            }
            
            .active {
                background-color: #426AFB;
            }
        `}
                    </style>
                </div>

            </div>
            <div style={{ position: 'relative', display: 'grid', placeItems: 'center', backgroundColor: '#fcfcfc' }}>
                <div>
                    <img src={basicinfo1} alt="svg" style={{ height: '17em', transform: 'translate(0%, -20%)' }} />
                </div>
                <div style={{ position: 'absolute', bottom: '0', right: '0' }}>
                    <img src={bottom} alt="bottom" />
                </div>
            </div>
        </div>
    )
}

export default BasicInfo;
