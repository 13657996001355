import { combineReducers } from "redux";
import { clientLogin, checkUserAuth, clientSignup } from "./authReducer";
import { getClientData } from "./clientReducer";

const reducers = combineReducers({
  loginData: clientLogin,
  signupData: clientSignup,
  checkAuthData: checkUserAuth,
  clientData: getClientData
})

export default reducers; 