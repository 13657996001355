import { ActionTypes } from "../constant/action-types";

const initialState = {}

export const getClientData = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CLIENT_DATA:
      if (action.payload.message === 'Data Fetched') {
        state = { ...action.payload }
      }
      const newState = Object.assign({}, state)
      return newState

    default:
      return state;
  }
}