import React, { useState, useEffect } from 'react';
import { Input, Button, Checkbox, Divider, Select } from 'antd';
import toastr from 'toastr';
import { Top, Bottom, GoogleIcon, ReverieIcon, tick, tickGreen } from '../../assets'
import { List } from 'antd';
import { commonLogin, clientSignup } from "../../redux/actions/authAction";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';

const emailVal = (value) => {
    if (!value) {
        return 'Please enter Email';
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Please enter correct Email Id';
    }
    if (value.length > 50) {
        return 'Email should not exceed 50 characters';
    }
    return undefined;
};

const passwordVal = (value) => {
    if (!value) {
        return 'Please enter password';
    }
    if (value.length < 8) {
        return 'Password should be at least 8 characters long';
    }
    if (value.length > 20) {
        return 'Password should not exceed 20 characters';
    }
    if (!/[a-z]/.test(value)) {
        return 'Password should contain at least one lowercase letter';
    }
    if (!/[A-Z]/.test(value)) {
        return 'Password should contain atleast one uppercase letter';
    }
    if (!/\d/.test(value)) {
        return 'Password should contain at least one number';
    }
    if (/\s/.test(value)) {
        return 'Password should not contain spaces';
    }
    return undefined;
};


const SignUp = ({  }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [tnc, setTnc] = useState(false);
    const [is8Chars, setIs8Chars] = useState(false);
    const [isUppercase, setIsUppercase] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [emailWarning, setEmailWarning] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');
    const [confirmPasswordWarning, setConfirmPasswordWarning] = useState('');
    const [tncWarning, setTncWarning] = useState('');
    const history = useHistory()
    const dispatch = useDispatch()

    const userLoginInfo = useSelector(state => state.loginData);

    useEffect(() => {
        if (window.localStorage.getItem('vachak_auth')) {
          window.location.href = '/';
        }
      }, []);

    const checkUppercase = (value) => {
        let uppercase = false;
        let character;
        let i = 0;
        while (i <= value.length) {
            character = value.charAt(i);
            if (character.charCodeAt() >= 65 && character.charCodeAt() <= 90) uppercase = true;
            i++;
        }
        return uppercase;
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setIsUppercase(checkUppercase(value));
        if (value.length >= 8) {
            setIs8Chars(true);
        } else {
            setIs8Chars(false);
        }
        setPassword(value);
        if (value === confirmPassword) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    };
    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);

        if (value === password) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    };
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = () => {
        const emailError = emailVal(email);
        const passwordError = passwordVal(password);

        if (emailError) {
            setEmailWarning(emailError);
            setTimeout(() => {
                setEmailWarning("");
            }, 1500);
            return;
        }

        if (passwordError) {
            setPasswordWarning(passwordError);
            setTimeout(() => {
                setPasswordWarning("");
            }, 1500);
            return;
        }

        if (!confirmPassword) {
            setConfirmPasswordWarning('Please confirm password')
            setTimeout(() => {
                setConfirmPasswordWarning("");
            }, 1500);
            return
        }
        if (password != confirmPassword) {
            setConfirmPasswordWarning('Please ensure that you type the same password in both the Password and Confirm Password fields.');
            setTimeout(() => {
                setConfirmPasswordWarning("");
            }, 1500);
            return;
        }
        if (!tnc) {
            setTncWarning('Please accept our Terms of Service');
            setTimeout(() => {
                setTncWarning("");
            }, 1500);
            return;
        }
        axios.post('https://webreader.reverieinc.com/tts_web_api/check-email', {
            email: email,
            password: password
        })
            .then(response => {
                console.log(response.data);
                toastr.success('Sign up successful');
            })
            .catch(error => {
                console.error({ error });
                if (error.response && error.response.status === 400) {
                    toastr.warning('Email already registered');
                } else if (error.response && error.response.status === 401) {
                    toastr.warning('Invalid email or password');
                } else {
                    toastr.warning('Error in sign up process');
                }
            });

        dispatch(clientSignup({
            email: email, password: password
        })).then(() => {
            console.log("coming after signup");
            if (userLoginInfo && !isEmpty(userLoginInfo?.user)) {
                history.push("/");
            }
        });
        history.replace("/basicinfo")
    };

    return (<div style={{ height: 'calc(100vh - 64px)', position: 'relative', fontFamily: "'Lato', sans-serif", }}>
        <div
            style={{
                backgroundColor: '#fff', height: '100%', display: 'grid', gridTemplateRows: '15% 85%',
            }}
        >
            <div
                style={{
                    placeSelf: 'start end',
                    marginTop: '3em',
                    marginRight: '5em',
                    fontSize: '16px',
                    textAlign: 'right',
                    color: '#7F8693',
                }}
            >
                Already a member?{' '}
                <span
                    onClick={(e) => history.push('/signin')}
                    style={{ color: '#505FB1', cursor: 'pointer' }}
                >
                    Sign In
                </span>
            </div>

            <div style={{ textAlign: 'center', width: '30%', margin: '0 auto' }}>
                <img src={ReverieIcon} style={{ height: '37px', width: '46px', marginBottom: '1em' }} />
                <div
                    style={{ color: '#4F4F50', fontSize: '22px', textAlign: 'center', fontWeight: '700' }}
                >
                    Create an Account
                </div>
                <div style={{ marginTop: '2em' }}>
                    <label
                        style={{
                            display: 'block',
                            textAlign: 'left',
                            color: '#4F4F50',
                            fontSize: '16px',
                            marginBottom: '.5em',
                        }}
                    >
                        Email
                    </label>
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="e.g. rahul.m@xyz.com"
                        style={{
                            marginLeft: '-.5em',
                            height: '3.7em',
                            borderRadius: '6px',
                            color: '#7F8693',
                            width: '30.6em',
                            border: '0 solid #CBD2E7',
                            fontSize: '16px',
                            paddingLeft: '.4em'
                        }}
                    />
                    {emailWarning && <div style={{ color: '#FE6D60', textAlign: 'left', marginLeft: '.3em' }}>{emailWarning}</div>}
                </div>
                <div style={{ marginTop: '2em' }}>
                    <label
                        style={{
                            display: 'block',
                            textAlign: 'left',
                            color: '#4F4F50',
                            fontSize: '16px',
                            marginBottom: '.5em',
                        }}
                    >
                        Create Password
                    </label>
                    <TextField
                        type={passwordVisible ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter your password here"
                        variant="outlined"
                        InputProps={{
                            style: {
                                height: '3.5em',
                                borderRadius: '6px',
                                color: '#7F8693',
                                width: '30.6em',
                                border: '0 solid #CBD2E7',
                                fontSize: '16px',
                                paddingLeft: '.4em'
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {passwordWarning && <div style={{ color: '#FE6D60', textAlign: 'left', marginLeft: '.3em' }}>{passwordWarning}</div>}
                    <List
                        dataSource={['Use at least 8 characters', 'Use Upper and Lower case characters', 'Use one or more numbers',]}
                        renderItem={(item) => (
                            <li style={{ color: '#4F4F50', textAlign: 'left', fontSize: '14px', listStyle: 'none' }}>
                                <span style={{ color: '#FE6D60', marginRight: '8px', fontSize: '20px' }}>&bull;</span>
                                {item}
                            </li>)}
                    />
                </div>
                <div style={{ marginTop: '2em' }}>
                    <label
                        style={{
                            display: 'block',
                            textAlign: 'left',
                            color: '#4F4F50',
                            fontSize: '16px',
                            marginBottom: '.5em',
                        }}
                    >
                        Confirm Password
                    </label>
                    <TextField
                        type={passwordVisible ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm your password"
                        InputProps={{
                            style: {
                                height: '3.5em',
                                borderRadius: '6px',
                                color: '#7F8693',
                                width: '30.6em',
                                border: '0 solid #CBD2E7',
                                fontSize: '16px',
                                paddingLeft: '.4em'
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {confirmPasswordWarning && <div style={{ color: '#FE6D60', textAlign: 'left', marginLeft: '.3em' }}>{confirmPasswordWarning}</div>}
                </div>
                <div
                    style={{
                        display: 'flex', justifyContent: 'space-between', marginTop: '2em', color: '#7F8693',
                    }}
                >
                    <div>
                        <Checkbox
                            checked={tnc}
                            onChange={(e) => setTnc(e.target.checked)}
                        />
                        <span style={{ marginLeft: '.5em' }}>
                            I accept the Terms of Service
                        </span>
                        {tncWarning && <div style={{ color: '#FE6D60', textAlign: 'left', marginLeft: '.3em' }}>{tncWarning}</div>}
                    </div>
                </div>
                <div style={{ marginTop: '2em' }}>
                    <Button type="primary" className="basic-btn" onClick={handleSubmit} style={{
                        backgroundColor: '#FE6D60',
                        color: 'white',
                        width: '24.5em',
                        height: '3em',
                        border: '1px solid #FE6D60',
                        borderRadius: '4px',
                        fontSize: '20px'
                    }}>Sign Up</Button>
                </div>
                <div style={{ marginTop: '3em' }}>
                    <Divider
                        style={{
                            color: '#7F8693', fontSize: '14px', borderColor: '#DADCE0', fontWeight: 'normal',
                        }}
                    >
                        Or Continue with
                    </Divider>
                </div>
                <div
                    style={{
                        display: 'flex', justifyContent: 'space-between', marginTop: '2em',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <Button
                            style={{
                                height: '4em',
                                padding: '.5em 2em',
                                borderRadius: '5px',
                                marginBottom: '4em',
                                border: '1px solid #CBD2E7',
                                backgroundColor: 'white',
                                marginLeft: '10em',
                                color: '#7F8693',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={GoogleIcon}
                                style={{ height: '1.3em', width: '1.3em', marginRight: '.7em' }}
                            />
                            <span>Sign in with Google</span>
                        </Button>
                    </div>

                </div>
            </div>
        </div>
        <div style={{ position: 'absolute', top: '-.6em', left: '5em' }}>
            <img src={Top} alt="top" />
        </div>
        <div style={{ position: 'absolute', bottom: '-25em', right: '-.6em' }}>
            <img src={Bottom} alt="bottom" />
        </div>
    </div>);
};

export default SignUp;

// export default connect(
//     (state) => ({
//         user: state.get('auth').user,
//     }),
//     (dispatch) => ({
//         authReducer: AuthReducer.getActions(dispatch),
//     })
// )(SignUp);
