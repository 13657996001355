import { React, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '2em',
        fontFamily: "'Lato', sans-serif",
        color: '#4F4F50',
        fontWeight: '700',
        lineHeight: '1.7em',
    },
    container: {
        border: `1px solid #CBD2E7`,
        width: `100%`,
        margin: `1px`,
        paddingBottom: '2em',
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        justifyContent: `space-between`,
        borderRadius: '4px',
    },
    innerContainer: {
        border: '1px solid #CBD2E7',
        backgroundColor: '#F3F6FC',
        fontWeight: '500',
        fontSize: '16px',
        borderRadius: '4px',
        padding: '1.5em',
    },
    sideButton: {
        height: '48px',
        width: '168px',
        textTransform: 'none',
        marginBottom: '.6em',
        fontWeight: '700'
    }
}));

export const IntegrationPage = () => {
    const classes = useStyles();
    const codeRef = useRef(null);
    const handleCopyScript = () => {
        const codeElement = codeRef.current;
        const range = document.createRange();
        range.selectNode(codeElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    };
    const handleCopyApiKey = () => {
        const apiKey = "0ceace79bae4ddf77ae8f312baad8abb0e717a5a";
        navigator.clipboard.writeText(apiKey);
      };
    const handleCopyAppID = () => {
        const appId = "14af756a4cfc9d0386b9ee4143c7a892b91ad135";
        navigator.clipboard.writeText(appId);
      };
    
    const handleCopyScriptTag= () => {
        const tag = '<script type="text/javascript" src="https://webreader.reverieinc.com/api/widget"></script>';
        navigator.clipboard.writeText(tag);
      };
    

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '28px', marginBottom: '.6em' }}>Integration</div>
                <div style={{ color: '#7F8693', fontSize: '20px', marginBottom: '1em' }}>JS Code</div>
            </div>
            <Container className={classes.container}>
                <div style={{ fontSize: '20px' }}> Step 1</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '.6em' }}>
                    <div style={{ fontSize: '18px' }}> Copy and paste this script in the place where you want to embed the audio player in your articles.</div>
                    <Button className={classes.sideButton} style={{ backgroundColor: '#FE6D60', color: 'white' }} onClick={handleCopyScript}>Copy Script</Button>
                </div>
                <div className={classes.innerContainer} style={{ height: '550px', marginBottom: '1em' }}>
                    <pre ref={codeRef} style={{ fontStyle: 'none', letterSpacing: '-.05em', fontSize: '16px', lineHeight: '35px' }}>
                        {`<script type="text/javascript">vachak({
    apikey: "0ceace79bae4ddf77ae8f312baad8abb0e717a5a",
    projectId: "14af756a4cfc9d0386b9ee4143c7a892b91ad135",
    uiConfig: {
      position: {
        top: "35%",
        left: "20%"
      },
      fontFamily: "lato",
      theme: "#EEB244",
      size: "medium"
    },
    sourceLanguage: "te",
    textNodes: ["contentTitle wow bounceInRight", "mainContent"]
  })</script>>`}
                    </pre>


                </div>
                <div style={{ fontSize: '16px' }}>Note: Integrate the script to your website with the given below <span style={{ color: '#426AFB' }}> REV_API_KEY or REV_APP_ID.</span></div>
            </Container>
            <Box mt={2}></Box>
            <Container className={classes.container}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{ fontSize: '20px', marginRight:'5px' }}> Step</div>
                    <div style={{ fontSize: '20px' }}> 2</div>
                    </div>
                    <Button className={classes.sideButton} style={{ border: '1px solid #4F4F50', marginLeft: '60em', color: '#4F4F50' }} onClick={handleCopyApiKey}>Copy API Key</Button>
                    <Button className={classes.sideButton} style={{ backgroundColor: '#FE6D60', color: 'white', marginLeft:'3em'}} onClick={handleCopyAppID}>Copy Script</Button>
                </div>

                <div style={{ fontSize: '18px', }}> Generate the TTS - three things are required</div>
                <ol>
                    <li>API Key</li>
                    <li>Project ID</li>
                    <li>Class name of the text container for which audio needs to be generated</li>
                </ol>
                <div style={{ fontSize: '16px', marginBottom: '1em' }}>Replace the REV_API_KEY and REV_APP_ID with the given credentials.</div>
                <div className={classes.innerContainer} style={{ height: '70px' }}>
                    <div style={{ fontWeight: '700' }}>const REV_API_KEY = "0ceace79bae4ddf77ae8f312baad8abb0e717a5a";</div>
                    <div style={{ fontWeight: '700' }}>const REV_PROJECT_ID = "14af756a4cfc9d0386b9ee4143c7a892b91ad135";</div>
                </div>
            </Container>
            <Box mt={2}></Box>
            <Container className={classes.container}>
                <div style={{ fontSize: '20px', marginBottom: '1em', marginLeft: '.2em' }}> Step 3</div>

                <div style={{ fontSize: '18px', marginBottom: '1em' }}>  Add the class name in the classNameArray.</div>
                <div className={classes.innerContainer} style={{ height: '20px', marginBottom: '1em' }}>
                    <div style={{ fontWeight: '700' }}>let classNameArray = ["contentTitle wow bounceInRight", "mainContent"]</div>
                </div>
                <div style={{ fontSize: '16px' }}>Here <span style={{ color: '#426AFB' }}>"contentTitle wow bounceInRight", "mainContent" </span> is the class name of the text containers.</div>
            </Container>
            <Container className={classes.container} style={{ marginTop: '1em' }}>
                <div style={{ fontSize: '20px', marginBottom: '1em' }}> Step 4</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '18px', }}> Add the script tag in the html page</div>
                    <Button className={classes.sideButton} style={{ backgroundColor: '#FE6D60', color: 'white', }} onClick={handleCopyScriptTag}>Copy Script Tag</Button>
                </div>
                <div className={classes.innerContainer} style={{ height: '20px' }}>
                    <code style={{ fontStyle: 'none', letterSpacing: '-.05em', fontSize: '16px', lineHeight: '35px' ,}}>{'<script type="text/javascript" src="https://webreader.reverieinc.com/api/widget"></script>'}</code>
                </div>
            </Container>
        </div>
    );
};
