import { ActionTypes } from "../constant/action-types";

const initialState = {
  user: {},
  error: null,
  message: ''
}

export const clientLogin = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLIENT_LOGIN:
      if (action?.payload?.success === true) {
        state.user = action?.payload?.data?.user
        state.message = action?.payload?.message
        window.localStorage.setItem('vachak_auth', action?.payload?.data?.auth);
      } else {
        // alert(action?.payload?.message)
      }
      const newState = Object.assign({}, state)
      return newState;
    default:
      return state;
  }
}

export const clientSignup = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLIENT_SIGNUP:
      if (action?.payload?.success === true) {

        console.log("------")
        console.log({payload: action.payload});
        console.log("------")

        state.user = action?.payload?.data?.user
        // state.message = action?.payload?.message
        window.localStorage.setItem('vachak_auth', action?.payload?.data?.auth);
      } else {
        // alert(action?.payload?.message)
      }
      const newState = Object.assign({}, state)
      return newState;
    default:
      return state;
  }
}

export const checkUserAuth = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHECK_USER_AUTH:
      if (action?.payload?.success) {
        state.user = action?.payload?.data?.user
        window.localStorage.setItem('vachak_auth', action?.payload?.data?.auth);
      }
      const newState = Object.assign({}, state)
      return newState;
    default:
      return state;
  }
}
