const config = {
    // Local Api Url
    // ApiUrl: 'http://localhost:5000/api/',
    // New Dev Api Url
    ApiUrl: "http://20.193.188.236:3001/api/",
    translateUrl: 'https://revapi.reverieinc.com/translate',
    translateHeaders: {
      // 'REV-APP-ID': 'com.nmtui',
      tgt_lang: 'hi',
      'REV-APPNAME': 'localization',
      src_lang: 'en',
      'Content-Type': 'application/json;charset=UTF-8',
      // 'REV-API-KEY': '8cedb429025812e0e9ec1e80f1efe04ce94eabf6',
      'REV-APP-ID': 'rev.nlurd',
      'REV-API-KEY': '46a92d8a6b2130b47098e750d9fb046fa37797b9',
    },
  };
  
  export default config;
  