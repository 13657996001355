import * as Auth from "../apis/Auth";

import { ActionTypes } from "../constant/action-types";

export const clientLogin = (params) => async (dispatch) => {
  const response = await Auth.login(params);
  dispatch({ type: ActionTypes.CLIENT_LOGIN, payload: response.data })
}

export const commonLogin = (params) => async (dispatch) => {
  const response = await Auth.authLogin(params);
  dispatch({ type: ActionTypes.CLIENT_LOGIN, payload: response.data })
}

export const clientSignup = (params) => async (dispatch) => {
  const response = await Auth.authSignup(params);
  dispatch({ type: ActionTypes.CLIENT_LOGIN, payload: response.data })
}

// check user auth
export const checkUserAuth = (params) => async (dispatch) => {
  const response = await Auth.checkAuth(params);
  dispatch({ type: ActionTypes.CLIENT_LOGIN, payload: response?.data })
}
