import React, { useState, useEffect } from "react";
import { Input, Button, Checkbox, Divider } from 'antd';
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { GoogleIcon, ReverieIcon, Display } from "../../assets";
import { commonLogin } from "../../redux/actions/authAction";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const emailVal = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email' : undefined);

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [emailWarning, setEmailWarning] = useState('');
    const [passwordWarning, setPasswordWarning] = useState('');
    const history = useHistory()
    const dispatch = useDispatch()
    const userLoginInfo = useSelector(state => state.loginData);

    useEffect(() => {
        if (window.localStorage.getItem('vachak_auth')) {
          window.location.href = '/';
        }
      }, []);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = () => {
        setEmailWarning('');
        setPasswordWarning('');

        if (!email) {
            setEmailWarning('Please enter correct Email Id');
            setTimeout(() => {
                setEmailWarning("");
            }, 1500);
            return;
        }

        if (emailVal(email)) {
            setEmailWarning('Invalid Email Id');
            setTimeout(() => {
                setEmailWarning("");
            }, 1500);
            return;
        }

        if (!password || password.trim().length === 0) {
            setPasswordWarning('Please enter correct password');
            setTimeout(() => {
                setPasswordWarning("");
            }, 1500);
            return;
        }
        dispatch(commonLogin({
            email: email, password: password
        })).then(() => {
            if (userLoginInfo && !isEmpty(userLoginInfo?.user)) {
                history.replace("/");
            }
        });
        history.replace("/")
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    const MainContainer = {
        display: 'grid', gridTemplateColumns: '55% 45%', height: '102vh', fontFamily: "'Lato', sans-serif",
    }

    return (<div style={MainContainer}>
        <div style={{ backgroundColor: '#F0EFF4', position: 'relative' }}>
            <div style={{ position: 'absolute', bottom: '20%', left: '10%', width: '640px', height: '400px' }}>
                <img src={Display} alt="top" />
            </div>
        </div>
        <div style={{ backgroundColor: '#fafafa' }}>
            <div style={{
                placeSelf: 'start end',
                marginTop: '2em',
                marginRight: '2em',
                fontSize: '16px',
                textAlign: 'right',
                color: '#7F8693'
            }}>
                Dont have an account? <span
                    onClick={e => history.push('/signup')}
                    style={{ color: '#3457B4', cursor: 'pointer' }}>Sign up</span>
            </div>
            <div style={{ marginTop: '3em' }}>
                <img src={ReverieIcon} style={{ height: '37px', width: '46px', marginLeft: '46%' }} />
            </div>
            <div style={{ textAlign: 'center', width: '70%', margin: '0 auto' }}>
                <div style={{ color: '#4F4F50', fontSize: '24px', textAlign: 'center' }}>
                    Sign In
                </div>
                <div style={{ marginTop: '2em' }}>
                    <label style={{
                        display: 'block', textAlign: 'left', fontSize: '18px', marginBottom: '.2em', color: "#4F4F50"
                    }}>Email</label>
                    <TextField value={email} onChange={e => setEmail(e.target.value)}
                        placeholder="eg. anumeha@gmail.com" style={{
                            marginLeft: '-.5em',
                            height: '3.7em',
                            borderRadius: '6px',
                            color: '#7F8693',
                            width: '30.6em',
                            border: '0 solid #CBD2E7',
                            fontSize: '16px',
                            paddingLeft: '.4em'
                        }} />
                    {emailWarning && <div style={{ color: '#FE6D60', textAlign: 'left', marginLeft: '1.1em' }}>{emailWarning}</div>}
                </div>
                <div style={{ marginTop: '2em' }}>
                    <label
                        style={{
                            display: 'block',
                            textAlign: 'left',
                            color: '#4F4F50',
                            fontSize: '18px',
                            marginBottom: '.2em',
                        }}
                    >
                        Password
                    </label>
                    <TextField
                        type={passwordVisible ? 'text' : 'password'}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleEnter}
                        placeholder="Enter your password"
                        InputProps={{
                            style: {
                                height: '3.5em',
                                borderRadius: '6px',
                                color: '#7F8693',
                                width: '30.6em',
                                border: '0 solid #CBD2E7',
                                fontSize: '16px',
                                paddingLeft: '.4em'
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {passwordWarning && <div style={{ color: '#FE6D60', textAlign: 'left', marginLeft: '1.1em' }}>{passwordWarning}</div>}

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '3em', color: '#7F8693' }}>
                    <div>
                        <Checkbox />
                        <span style={{ marginLeft: '.5em' }}>Keep me Signed in</span>
                    </div>
                    <div>
                        Forgot Password?
                    </div>
                </div>
                <div style={{ marginTop: '2em' }}>
                    <Button type="primary" className="basic-btn" onClick={handleSubmit} style={{
                        backgroundColor: '#FE6D60',
                        color: 'white',
                        width: '24.5em',
                        height: '3em',
                        border: '1px solid #FE6D60',
                        borderRadius: '4px',
                        fontSize: '20px'
                    }}>Sign In</Button>
                </div>
                <div style={{ marginTop: '3em' }}>
                    <Divider
                        style={{ color: '#7F8693', fontSize: '14px', borderColor: '#DADCE0', fontWeight: 'normal' }}>Or
                        Continue with</Divider>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <button
                        style={{
                            height: '4em',
                            padding: '.5em 2em',
                            borderRadius: '5px',
                            marginBottom: '4em',
                            border: '1px solid #CBD2E7',
                            backgroundColor: 'white',
                            marginLeft: '10em',
                            color: '#7F8693',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '1em',
                            marginLeft: '12.4em'
                        }}
                    >
                        <img
                            src={GoogleIcon}
                            style={{ height: '1.3em', width: '1.3em', marginRight: '.7em' }}
                        />
                        <span>Sign in with Google</span>
                    </button>
                </div>

            </div>
        </div>

    </div>)
}

export default SignIn;