import { React, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Projects,
  Integration,
  Support,
  Ellipse,
  ReverieGroup,
  Exit,
  Mail
} from '../../assets';
import './layout.styles.css';

const Layout = ({ children }) => {
  const history = useHistory();
  const handleLogout = () => {
    window.localStorage.removeItem('vachak_auth');
    history.replace('/signin')
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownItemClick = () => {
    handleLogout();
    setDropdownOpen(false);
  };

  return (
    <div className="layout">
      <div className="header">
        <img src={ReverieGroup} />
        <div className="spacer"></div>
        <div className="dropdown">
          <img src={Ellipse} style={{ width: '3.5em', height: '3.5em', cursor: 'pointer' }} />
          <div className='dropdown-content'>
            <div className='drop' style={{ display: 'flex', flexDirection: 'column', fontFamily: "'Lato', sans-serif", padding: '1em' }}>
              <div className='sub-1' style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='profile' style={{ marginRight: '1em' }}>
                  <img src={Ellipse} style={{ width: '3em', height: '3em', cursor: 'pointer' }} />
                </div>
                <div className='mail' style={{ display: 'flex', flexDirection: 'column', marginRight: '2em' }}>
                  <div style={{ fontSize: '18px', color: '#4F4F50', fontWeight: '700', paddingBottom: '.2em' }}>Username</div>
                  <div style={{ fontSize: '16px', color: '#7F8693' }}>emailid@gmail.com</div>
                </div>
                <div className='edit' style={{ paddingTop: '.3em' }}>
                  <button onClick={(e) => history.push('/edit-profile')} style={{
                    backgroundColor: '#FE6D60',
                    color: 'white',
                    width: '7.5em',
                    height: '2.1em',
                    border: '1px solid #FE6D60',
                    borderRadius: '4px',
                    fontSize: '18px',
                    borderRadius: '3px',
                    cursor: 'pointer'
                  }}> Edit Profile</button>
                </div>
              </div>
              <div className='verify' style={{ display: 'flex', flexDirection: 'row', marginTop: '2em', marginLeft: '.5em' }} onClick={(e) => history.push('/edit-profile')}>
                <img src={Mail} />
                <div style={{ color: '#7F8693', fontWeight: '700', fontSize: '16px', marginLeft: '1em', paddingTop: '.5em', cursor: 'pointer' }} onClick={(e) => history.push('/edit-profile')}>Verify your Email</div>
              </div>
              <div className='logout' style={{ display: 'flex', flexDirection: 'row', marginTop: '1.4em', marginLeft: '.5em' }} onClick={handleDropdownItemClick}>
                <img src={Exit} />
                <div style={{ color: '#7F8693', fontWeight: '700', fontSize: '16px', marginLeft: '1em', paddingTop: '.2em', cursor: 'pointer' }}>
                  <a target="_blank" rel="noopener noreferrer" onClick={handleDropdownItemClick}>Log Out</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sidebar">
          <div className="nav-item">
            <img src={Projects} />
            <div>Projects</div>
          </div>
          <div
            className="nav-item"
            onClick={(e) => history.push('/integration')}
          >
            <img src={Integration} />
            <div style={{ color: 'white' }}>Integration/ Documents</div>
          </div>
          <div className="nav-item">
            <img src={Support} />
            <div>Support/ FAQs</div>
          </div>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
